import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'

const Datenschutz = ({ data, location }) => (
	<Layout location={location}>
		<div
			dangerouslySetInnerHTML={{ __html: data.file.childMarkdownRemark.html }}
			className={'markdown'}
		/>
	</Layout>
)

export default Datenschutz

export const datenschutzQuery = graphql`
  query datenschutzQuery {
    file(relativePath: { eq: "pages/datenschutzerklaerung.md" }) {
      childMarkdownRemark {
        html
      }
    }
  }
`
